<template>
  <v-row>
    <v-card class="w-full col-12">
      <v-card-title>
        Coming Soon...
      </v-card-title>
    </v-card>

    <v-card v-if="false" class="w-full col-12">
      <v-card-title>Settings</v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="col-4">
            <v-select
              outlined
              dense
              clearable
              label="Frequency"
              :items="['Hourly', 'Each 3rd-Hour', 'Each 6th-Hour', 'Each 12th-Hour', 'Daily']"
            ></v-select>
          </v-col>
          <v-col class="col-4">
            <v-select
              outlined
              dense
              clearable
              label="Full Backup Period"
              :items="['Hourly', 'Each 3rd-Hour', 'Each 6th-Hour', 'Each 12th-Hour', 'Daily']"
            ></v-select>
          </v-col>
          <v-col class="col-4">
            <v-select
              outlined
              dense
              clearable
              label="Keep Backups"
              :items="['Hourly For Half Day', 'Daily For Week', 'Weekly For Month']"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
      </v-card-actions>
    </v-card>

    <v-card v-if="false" class="w-full col-12 mt-6">
      <v-card-title>Full Backups</v-card-title>

      <v-simple-table class="w-full">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                ID
              </th>
              <th class="text-left">
                Type
              </th>
              <th class="text-left">
                Name
              </th>
              <th class="text-left">
                Date
              </th>
              <th class="text-left">
                Size
              </th>
              <th class="text-left">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(element) in fullBackups"
              :key="element.id"
              class="w-full"
            >
              <td>{{ element.id }}</td>
              <td>{{ element.type }}</td>
              <td>{{ element.name }}</td>
              <td>{{ element.date }}</td>
              <td>{{ element.size }}</td>

              <td class="col-1">
                <!--                <v-icon class="delete-task-btn" @click="() => {}">-->
                <!--                  {{ icons.mdiDelete }}-->
                <!--                </v-icon>-->
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

    <v-card v-if="false" class="w-full col-12 mt-6">
      <v-card-title>Incremental Backups</v-card-title>

      <v-simple-table class="w-full">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                ID
              </th>
              <th class="text-left">
                Type
              </th>
              <th class="text-left">
                Name
              </th>
              <th class="text-left">
                Date
              </th>
              <th class="text-left">
                Size
              </th>
              <th class="text-left">
                Based On
              </th>
              <th class="text-left">
                Tree Level
              </th>
              <th class="text-left">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(element) in incrementalBackups"
              :key="element.id"
              class="w-full"
            >
              <td>{{ element.id }}</td>
              <td>{{ element.type }}</td>
              <td>{{ element.name }}</td>
              <td>{{ element.date }}</td>
              <td>{{ element.size }}</td>
              <td>{{ element.based_on }}</td>
              <td>{{ element.tree_level }}</td>

              <td class="col-1">
              <!--                <v-icon class="delete-task-btn" @click="() => {}">-->
              <!--                  {{ icons.mdiDelete }}-->
              <!--                </v-icon>-->
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      fullBackups: [
        {
          id: 1,
          type: 'Database',
          name: 'magento_db',
          date: '02-02-2022 17:03',
          size: '74.3 MB',
        }, {
          id: 2,
          type: 'Database',
          name: 'magento_db',
          date: '02-02-2022 17:03',
          size: '74.3 MB',
        },
      ],
      incrementalBackups: [
        {
          id: 1,
          type: 'Database',
          name: 'magento_db',
          date: '02-02-2022 17:03',
          size: '4.3 MB',
          based_on: 'Full #1',
          tree_level: '1',
        }, {
          id: 2,
          type: 'Database',
          name: 'magento_db',
          date: '02-02-2022 17:03',
          size: '3.3 MB',
          based_on: 'Increment #1',
          tree_level: '2',
        },
      ],
    }
  },
  created() {
    // watch the params of the route to fetch the data again
    // this.$watch(
    //   () => this.$route.params,
    //   () => {
    //     this.fetchData()
    //   },
    //   // fetch the data when the view is created and the data is
    //   // already being observed
    //   { immediate: true }
    // )
  },
  methods: {

  },
}
</script>
